@import "media";

@mixin font($size, $weight, $height: 1) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
}

//$font-family-head: HyundaiSansHead, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
//  Cantarell, "Helvetica Neue", sans-serif;
//
//
//var(--font-family-text): HyundaiSansText, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
//  Cantarell, "Helvetica Neue", sans-serif;

%text_h1_L {
  @include font(55px, 500, 60px);
  font-family: var(--font-family-head);

  @include desktop {
    @include font(45px, 500, 50px);
  }

  @include notebook {
    @include font(35px, 500, 40px);
  }
}

%text_h1_M {
  @include font(48px, 300, 50px);
  font-family: var(--font-family-head);
  @include desktop {
    @include font(38px, 300, 40px);
  }

  @include notebook {
    @include font(28px, 300, 30px);
  }
}

%text_h1 {
  @include font(55px, 500, 80%);
  font-family: var(--font-family-head);
}
%text_h2 {
  @include font(42px, 500, 48px);
  font-family: var(--font-family-head);
}
%text_h2_M {
  @include font(42px, 300, 44px);
  font-family: var(--font-family-head);
}
%text_h3 {
  @include font(30px, 500, 36px);
  font-family: var(--font-family-head);
}

%text_h3_M {
  @include font(30px, 300, 32px);
  font-family: var(--font-family-head);
}

%text_h4 {
  @include font(20px, 500, 24px);
  font-family: var(--font-family-head);
}

%text_h5 {
  @include font(16px, 500, 24px);
  font-family: var(--font-family-head);
}
%text_h5_gen {
  @include font(18px, 400, 20px);
  font-family: var(--font-family-head);
}

%text_h6 {
  @include font(12px, 500, 120%);
  font-family: var(--font-family-head);
}
%text_subline_1 {
  @include font(30px, 400, 32px);
  font-family: var(--font-family-head);
}

%text_subline_2 {
  @include font(22px, 400, 32px);
  font-family: var(--font-family-head);
}

%text_input {
  @include font(14px, 400, 16px);
  font-family: var(--font-family-head);
}

%text_input_m {
  @include font(16px, 400, 24px);
  font-family: var(--font-family-head);
}

%text_button {
  @include font(16px, 400, 22px);
  font-family: var(--font-family-head);
}

%text_radio {
  @include font(14px, 500, 120%);
  font-family: var(--font-family-head);
}

%text_link_in_head {
  @include font(16px, 400, 100%);
  font-family: var(--font-family-head);
}

%text_link_in_text {
  @include font(14px, 400, 100%);
  font-family: var(--font-family-text);
}

%text_main {
  @include font(16px, 400, 24px);
  font-family: var(--font-family-text);
}
%text_main_medium {
  @include font(14px, 400, 20px);
  font-family: var(--font-family-text);
}

%text_main_small {
  @include font(12px, 400, 100%);
  font-family: var(--font-family-text);
}

%text_link_in_nav {
  @include font(14px, 500, 16px);
  font-family: var(--font-family-head);
}

%text_in_header_user_name {
  @include font(12px, 400, 16px);
  font-family: var(--font-family-head);
}

%text_in_header_user_role {
  @include font(12px, 400, 18px);
  font-family: var(--font-family-text);
}

%text_tabs {
  @include font(18px, 300, 20px);
  font-family: var(--font-family-text);
}
