@import "src/assets/styles/fonts";
@import "src/assets/styles/indents";
@import "src/assets/styles/colors";
@import "src/assets/styles/shadow";

@mixin input-theme-company {
  display: block;
  width: 100%;
  @extend %text_input;
  line-height: 22px;
  box-shadow: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  transition: 0.3s;

  &::-ms-clear {
    display: none;
  }
  &::-ms-reveal {
    display: none;
  }
  &-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
  &::-webkit-input-placeholder {
    color: $color_text_second;
  }
  &:-moz-placeholder {
    color: $color_text_second;
  }
  &::-moz-placeholder {
    color: $color_text_second;
  }
  &:-ms-input-placeholder {
    color: $color_text_second;
  }
  &:disabled {
    opacity: 0.5;
  }
  &.bordered {
    border-color: $color_sand;
  }
}
@mixin label-theme-company {
  @extend %text_h5;
  padding-bottom: $indentS;
  display: block;
}

@mixin input-children {
  .container {
    position: relative;
  }
  .error {
    position: absolute;
    left: 0;
    text-align: left;
    font-size: 12px;
    color: $color_active_red;
  }
  .status {
    position: absolute;
    text-align: left;
    font-size: 12px;
    color: $color_text_black;
  }  
}
