@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/fonts";

@mixin link-company {
  display: inline-block;
  font-family: var(--font-family-head);
  @include font(18px, 500, 16px);
  border: none;
  padding: 0;
  background: none;
  transition: 0.3s;
  cursor: pointer;
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &:hover {
    text-decoration: underline;
  }

  &.small {
    @include font(14px, 400, 16px);
  }
  &.normal {
    @include font(16px, 500, 24px);
  }
  &.big {
    @include font(18px, 500, 16px);
  }
  &.media {
    font-weight: 500;
  }
  &.regular {
    font-weight: 400;
  }

  &.head {
    font-family: var(--font-family-head);
  }
  &.text {
    font-family: var(--font-family-text);
  }
  &.underline {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &.gray {
    color: $color_text_second;
  }
}
