$color_active_red: #e63312;
$color_main_blue: #002c5f;
$color_sand: #e4dcd3;
$color_second_blue: #00aad2;
$color_second_blue_hover: #10b5dc;
$color_white: #ffffff;
$color_main_black: #000000;

$color_bg_gray: #f6f3f2;
$color_bg_black: #000000;
$color_bg_sand: #e4dcd3;
$color_bg_white: #ffffff;
$color_bg_normal_gray: #e5e5e5;
$color_bg_icon: #b7b7b7;

$color-input: #333333;

$color_text_second: #666666;
$color_text_black: #000000;
$color_text_third: #b7b7b7;
$color_text_white: #ffffff;
$color_text_gold: #996449;
$color_text_gray: #4d4d4d;
$color_text_gray_light: #999999;

$color_social_hover: #ad7b61;
$color_button_disable: #777673;
$color_button_hover: #103a71;
$color_light_grey_hover: #f8f8f8;
$color_light_grey_scroll_bar: #f1f1f1;
:export {
  color_active_red: $color_active_red;
  color_main_blue: $color_main_blue;
  color_sand: $color_sand;
  color_second_blue: $color_second_blue;
  color_second_blue_hover: $color_second_blue_hover;
  color_white: $color_white;
  color_main_black: $color_main_black;

  color_bg_gray: $color_bg_gray;
  color_bg_sand: $color_bg_sand;
  color_bg_white: $color_bg_white;
  color_bg_normal_gray: $color_bg_normal_gray;
  color_bg_black: $color_bg_black;
  color_bg_icon: $color_bg_icon;

  color-input: $color-input;

  color_text_second: $color_text_second;
  color_text_black: $color_text_black;
  color_text_third: $color_text_third;
  color_text_white: $color_text_white;
  color_text_gold: $color_text_gold;
  color_text_gray_light: $color_text_gray_light;

  color_social_hover: $color_social_hover;
  color_button_disable: $color_button_disable;
  color_button_hover: $color_button_hover;
  color_light_grey_hover: $color_light_grey_hover;
  color_light_grey_scroll_bar: $color_light_grey_scroll_bar;
}

$indent4XS: 0;
$indent3XS: 2px;
$indentXXS: 4px;
$indentXS: 8px;
$indentS: 16px;
$indentM: 24px;
$indentL: 32px;
$indentXL: 40px;
$indentXXL: 40px;
///todo поменял размер

$paddingMobile: 18px;

$container-full: 1504px;
$container-desktop: 1504px;
$container-notebook: 1216px;
$container-largeTablet: 960px;
$container-tablet: 688px;
$container-mobile: 288px;

@mixin full-screen {
  @media only screen and (max-width: 4060px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: 1599px) {
    @content;
  }
}

@mixin duble {
  @media only screen and (max-width: 1460px) {
    @content;
  }
}

@mixin notebook {
  @media only screen and (max-width: 1199px) {
    @content;
  }
}

@mixin largeTablet {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 759px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 375px) {
    @content;
  }
}

@import "../../../../assets/styles/media";

@mixin font($size, $weight, $height: 1) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
}

//$font-family-head: HyundaiSansHead, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
//  Cantarell, "Helvetica Neue", sans-serif;
//
//
//var(--font-family-text): HyundaiSansText, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
//  Cantarell, "Helvetica Neue", sans-serif;

%text_h1_L {
  @include font(55px, 500, 60px);
  font-family: var(--font-family-head);

  @include desktop {
    @include font(45px, 500, 50px);
  }

  @include notebook {
    @include font(35px, 500, 40px);
  }
}

%text_h1_M {
  @include font(48px, 300, 50px);
  font-family: var(--font-family-head);
  @include desktop {
    @include font(38px, 300, 40px);
  }

  @include notebook {
    @include font(28px, 300, 30px);
  }
}

%text_h1 {
  @include font(55px, 500, 80%);
  font-family: var(--font-family-head);
}
%text_h2 {
  @include font(42px, 500, 48px);
  font-family: var(--font-family-head);
}
%text_h2_M {
  @include font(42px, 300, 44px);
  font-family: var(--font-family-head);
}
%text_h3 {
  @include font(30px, 500, 36px);
  font-family: var(--font-family-head);
}

%text_h3_M {
  @include font(30px, 300, 32px);
  font-family: var(--font-family-head);
}

%text_h4 {
  @include font(20px, 500, 24px);
  font-family: var(--font-family-head);
}

%text_h5 {
  @include font(16px, 500, 24px);
  font-family: var(--font-family-head);
}
%text_h5_gen {
  @include font(18px, 400, 20px);
  font-family: var(--font-family-head);
}

%text_h6 {
  @include font(12px, 500, 120%);
  font-family: var(--font-family-head);
}
%text_subline_1 {
  @include font(30px, 400, 32px);
  font-family: var(--font-family-head);
}

%text_subline_2 {
  @include font(22px, 400, 32px);
  font-family: var(--font-family-head);
}

%text_input {
  @include font(14px, 400, 16px);
  font-family: var(--font-family-head);
}

%text_input_m {
  @include font(16px, 400, 24px);
  font-family: var(--font-family-head);
}

%text_button {
  @include font(16px, 400, 22px);
  font-family: var(--font-family-head);
}

%text_radio {
  @include font(14px, 500, 120%);
  font-family: var(--font-family-head);
}

%text_link_in_head {
  @include font(16px, 400, 100%);
  font-family: var(--font-family-head);
}

%text_link_in_text {
  @include font(14px, 400, 100%);
  font-family: var(--font-family-text);
}

%text_main {
  @include font(16px, 400, 24px);
  font-family: var(--font-family-text);
}
%text_main_medium {
  @include font(14px, 400, 20px);
  font-family: var(--font-family-text);
}

%text_main_small {
  @include font(12px, 400, 100%);
  font-family: var(--font-family-text);
}

%text_link_in_nav {
  @include font(14px, 500, 16px);
  font-family: var(--font-family-head);
}

%text_in_header_user_name {
  @include font(12px, 400, 16px);
  font-family: var(--font-family-head);
}

%text_in_header_user_role {
  @include font(12px, 400, 18px);
  font-family: var(--font-family-text);
}

%text_tabs {
  @include font(18px, 300, 20px);
  font-family: var(--font-family-text);
}

$shadow-focus: 0 8px 24px rgba(0, 0, 0, 0.16);

$hoverLinkSec: 0.3s;

.wrapper {
  display: flex;
  justify-content: space-between;
}
.title {
  margin: 0;
  padding-bottom: 20px;
}
.panel {
  display: flex;
}

.container {
  background: #f6f3f2;

  position: relative;
}
