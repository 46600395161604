@import "src/assets/styles/colors";
@import "src/assets/styles/indents";
@import "src/assets/styles/fonts";

@mixin button-theme-company {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  @extend %text_button;
  box-shadow: none;
  border: none;
  border-radius: 0;
  color: $color_white;
  text-align: center;
  padding: $indentS $indentXXL;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.3s;

  &:disabled {
    background-color: $color_button_disable;
    cursor: default;
  }

  &.small {
    padding: $indentXS + $indentXXS $indentL;
  }

  &.normal {
    padding: $indentS $indentXXL;
  }
  &.xs {
    height: 40px;
    min-width: 148px;
    @extend %text_h6;
    line-height: 40px;
    color: $color_text_black;
    padding: 0 $indentL;
  }
  &.noBorder {
    border: none;
  }
  &.fullWidth {
    width: 100%;
    display: block;
  }
}
