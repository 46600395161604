@font-face {
  font-family: "HyundaiSansHead";
  font-weight: 300;
  src: local("HyundaiSansHead-Light"), url("../fonts/HyundaiSansHead-Light.woff") format("woff");
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: "GenesisSansHead";
  font-weight: 300;
  src: local("GenesisSansHead-Light"), url("../fonts/GenesisSansHead-Light.otf") format("otf");
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansHead";
  font-weight: 400;
  src: local("HyundaiSansHead-Regular"), url("../fonts/HyundaiSansHead-Regular.woff") format("woff");
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: "GenesisSansHead";
  font-weight: 400;
  src: local("GenesisSansHead-Regular"), url("../fonts/GenesisSansHead-Regular.otf") format("otf");
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansHead";
  font-weight: 500;
  src: local("HyundaiSansHead-Medium"), url("../fonts/HyundaiSansHead-Medium.woff") format("woff");
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: "GenesisSansText";
  font-weight: 500;
  src: local("GenesisSansText-Medium"), url("../fonts/GenesisSansText-Medium.otf") format("otf");
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansHead";
  font-weight: 700;
  src: local("HyundaiSansHead-Bold"), url("../fonts/HyundaiSansHead-Bold.woff") format("woff");
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansText";
  font-weight: 700;
  src: local("GenesisSansText-Bold"), url("../fonts/GenesisSansText-Bold.otf") format("otf");
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansText";
  font-weight: 400;
  src: local("HyundaiSansText-Regular"), url("../fonts/HyundaiSansText-Regular.woff") format("woff");
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: "GenesisSansText";
  font-weight: 400;
  src: local("GenesisSansText-Regular"), url("../fonts/GenesisSansText-Regular.otf") format("otf");
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansText";
  font-weight: 500;
  src: local("HyundaiSansText-Medium"), url("../fonts/HyundaiSansText-Medium.woff") format("woff");
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansText";
  font-weight: 700;
  src: local("HyundaiSansText-Bold"), url("../fonts/HyundaiSansText-Bold.woff") format("woff");
  font-stretch: normal;
  font-style: normal;
}
