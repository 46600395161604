$container-full: 1504px;
$container-desktop: 1504px;
$container-notebook: 1216px;
$container-largeTablet: 960px;
$container-tablet: 688px;
$container-mobile: 288px;

@mixin full-screen {
  @media only screen and (max-width: 4060px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: 1599px) {
    @content;
  }
}

@mixin duble {
  @media only screen and (max-width: 1460px) {
    @content;
  }
}

@mixin notebook {
  @media only screen and (max-width: 1199px) {
    @content;
  }
}

@mixin largeTablet {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 759px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 375px) {
    @content;
  }
}
