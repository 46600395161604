$indent4XS: 0;
$indent3XS: 2px;
$indentXXS: 4px;
$indentXS: 8px;
$indentS: 16px;
$indentM: 24px;
$indentL: 32px;
$indentXL: 40px;
$indentXXL: 40px;
///todo поменял размер

$paddingMobile: 18px;
