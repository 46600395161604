$color_active_red: #e63312;
$color_main_blue: #002c5f;
$color_sand: #e4dcd3;
$color_second_blue: #00aad2;
$color_second_blue_hover: #10b5dc;
$color_white: #ffffff;
$color_main_black: #000000;

$color_bg_gray: #f6f3f2;
$color_bg_black: #000000;
$color_bg_sand: #e4dcd3;
$color_bg_white: #ffffff;
$color_bg_normal_gray: #e5e5e5;
$color_bg_icon: #b7b7b7;

$color-input: #333333;

$color_text_second: #666666;
$color_text_black: #000000;
$color_text_third: #b7b7b7;
$color_text_white: #ffffff;
$color_text_gold: #996449;
$color_text_gray: #4d4d4d;
$color_text_gray_light: #999999;

$color_social_hover: #ad7b61;
$color_button_disable: #777673;
$color_button_hover: #103a71;
$color_light_grey_hover: #f8f8f8;
$color_light_grey_scroll_bar: #f1f1f1;
:export {
  color_active_red: $color_active_red;
  color_main_blue: $color_main_blue;
  color_sand: $color_sand;
  color_second_blue: $color_second_blue;
  color_second_blue_hover: $color_second_blue_hover;
  color_white: $color_white;
  color_main_black: $color_main_black;

  color_bg_gray: $color_bg_gray;
  color_bg_sand: $color_bg_sand;
  color_bg_white: $color_bg_white;
  color_bg_normal_gray: $color_bg_normal_gray;
  color_bg_black: $color_bg_black;
  color_bg_icon: $color_bg_icon;

  color-input: $color-input;

  color_text_second: $color_text_second;
  color_text_black: $color_text_black;
  color_text_third: $color_text_third;
  color_text_white: $color_text_white;
  color_text_gold: $color_text_gold;
  color_text_gray_light: $color_text_gray_light;

  color_social_hover: $color_social_hover;
  color_button_disable: $color_button_disable;
  color_button_hover: $color_button_hover;
  color_light_grey_hover: $color_light_grey_hover;
  color_light_grey_scroll_bar: $color_light_grey_scroll_bar;
}
